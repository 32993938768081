import "./App.css";
import Page1 from "./components/page1";

function App() {
  return (
    <div className="App">
      <div className="fullpage">
        <Page1 />
      </div>
    </div>
  );
}

export default App;
