import React from "react";

const Page1 = () => {
  return (
    <>
      <div
        className="w-100"
        style={{
          background:
            "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(0,35,255,0.5) 100%), url(./assets/img/ri_1.png)",
          display: "flex",
          flexDirection: "row",
          height: "100%",
        }}
      >
        <div
          className="col-md-8"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "0 0 0 100px",
          }}
        >
          <img alt="ri_3.png" src="./assets/img/ri_3.png" />

          <div
            style={{
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "80pt",
              color: "#fff",
              padding: "75px 0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              lineHeight: "1.2",
            }}
          >
            <span>Are you READY</span>
            <br />
            <span>for the FUTURE?</span>
          </div>

          <button
            className="btn btn-light"
            style={{
              fontSize: "28pt",
              color: "#e52d27",
              borderRadius: "50px",
              padding: "15px 50px",
            }}
          >
            Get Started
          </button>
        </div>
        <div
          className="col-md-4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <img
            alt="ri_4.png"
            style={{
              width: "100%",
              // height: "100%",
            }}
            src="./assets/img/ri_4.png"
          />
        </div>
      </div>
    </>
  );
};

export default Page1;
